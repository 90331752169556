.button--flat {font-size:0.75rem;line-height:1rem;font-weight:500;text-transform:uppercase
}.button--flat.button>.icon {height:1rem;width:1rem
}.button--flat.button--violet {--tw-text-opacity:1;color:rgba(130, 114, 191, var(--tw-text-opacity))
}.button--flat.button--violet:hover {--tw-bg-opacity:1;background-color:rgba(242, 240, 249, var(--tw-bg-opacity))
}.button--flat.button--violet:active {--tw-bg-opacity:1;background-color:rgba(236, 233, 245, var(--tw-bg-opacity))
}.button--flat-full {font-size:0.75rem;line-height:1rem;font-weight:500;text-transform:uppercase
}.button--flat-full.button--violet {--tw-bg-opacity:1;background-color:rgba(133, 207, 195, var(--tw-bg-opacity));font-size:1.25rem;line-height:1.75rem;--tw-text-opacity:1;color:rgba(255, 255, 255, var(--tw-text-opacity))
}.button--flat-full.button--violet:hover {--tw-bg-opacity:1;background-color:rgba(33, 212, 201, var(--tw-bg-opacity))
}.button--flat-full.button--violet:active {--tw-bg-opacity:1;background-color:rgba(27, 168, 159, var(--tw-bg-opacity))
}.button--flat-outline {border-width:1px;font-size:0.75rem;line-height:1rem;font-weight:500;text-transform:uppercase
}.button--flat-outline.button--violet {--tw-border-opacity:1;border-color:rgba(145, 124, 199, var(--tw-border-opacity));--tw-text-opacity:1;color:rgba(130, 114, 191, var(--tw-text-opacity))
}.button--flat-outline.button--violet:hover {--tw-bg-opacity:1;background-color:rgba(242, 240, 249, var(--tw-bg-opacity))
}.button--flat-outline.button--violet:active {--tw-bg-opacity:1;background-color:rgba(229, 226, 243, var(--tw-bg-opacity))
}.button--plain.button--black {--tw-text-opacity:1;color:rgba(0, 0, 0, var(--tw-text-opacity))
}.button--plain.button--black:hover {--tw-text-opacity:0.6
}.button--plain.button--black:active {--tw-text-opacity:1
}.button--plain.button--violet {--tw-text-opacity:1;color:rgba(97, 76, 180, var(--tw-text-opacity))
}.button--plain.button--violet:hover {--tw-text-opacity:0.6
}.button--plain.button--violet:active {--tw-text-opacity:1
}.button--plain.button--violet-light {--tw-text-opacity:1;color:rgba(191, 182, 224, var(--tw-text-opacity))
}.button--plain.button--violet-light:hover {--tw-text-opacity:1;color:rgba(145, 124, 199, var(--tw-text-opacity))
}.button--plain.button--violet-light:active {--tw-text-opacity:1;color:rgba(97, 76, 180, var(--tw-text-opacity))
}.button--rounded, .button--rounded-full {font-weight:500;--tw-text-opacity:1;color:rgba(255, 255, 255, var(--tw-text-opacity))
}.button--rounded:not(.button--rounded-full), .button--rounded-full:not(.button--rounded-full) {border-radius:1.5rem
}.button--rounded.button:disabled, .button.button--rounded-full:disabled, .button--rounded.button:disabled:hover, .button--rounded.button:disabled:focus, .button--rounded.button:disabled:active {cursor:not-allowed;--tw-bg-opacity:1;background-color:rgba(229, 229, 229, var(--tw-bg-opacity));--tw-text-opacity:1;color:rgba(204, 204, 204, var(--tw-text-opacity))
}.button--rounded.button--blue, .button--blue.button--rounded-full {--tw-bg-opacity:1;background-color:rgba(85, 119, 186, var(--tw-bg-opacity))
}.button--rounded.button--blue:hover, .button--blue.button--rounded-full:hover {--tw-bg-opacity:1;background-color:rgba(64, 95, 156, var(--tw-bg-opacity))
}.button--rounded.button--blue:active, .button--blue.button--rounded-full:active {--tw-bg-opacity:1;background-color:rgba(56, 84, 138, var(--tw-bg-opacity))
}.button--rounded.button--gray-dark, .button--gray-dark.button--rounded-full {--tw-bg-opacity:1;background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
}.button--rounded.button--gray-dark:hover, .button--gray-dark.button--rounded-full:hover {--tw-bg-opacity:1;background-color:rgba(33, 33, 33, var(--tw-bg-opacity))
}.button--rounded.button--gray-dark:active, .button--gray-dark.button--rounded-full:active {--tw-bg-opacity:1;background-color:rgba(51, 51, 51, var(--tw-bg-opacity))
}.button--rounded.button--green, .button--green.button--rounded-full {--tw-bg-opacity:1;background-color:rgba(28, 111, 71, var(--tw-bg-opacity))
}.button--rounded.button--green:hover, .button--green.button--rounded-full:hover {--tw-bg-opacity:1;background-color:rgba(34, 149, 94, var(--tw-bg-opacity))
}.button--rounded.button--green:active, .button--green.button--rounded-full:active {--tw-bg-opacity:1;background-color:rgba(28, 111, 71, var(--tw-bg-opacity))
}.button--rounded.button--green-light, .button--green-light.button--rounded-full {--tw-bg-opacity:1;background-color:rgba(169, 221, 195, var(--tw-bg-opacity))
}.button--rounded.button--green-light:hover, .button--green-light.button--rounded-full:hover {--tw-bg-opacity:1;background-color:rgba(116, 206, 161, var(--tw-bg-opacity))
}.button--rounded.button--green-light:active, .button--green-light.button--rounded-full:active {--tw-bg-opacity:1;background-color:rgba(61, 178, 119, var(--tw-bg-opacity))
}.button--rounded.button--rose, .button--rose.button--rounded-full {--tw-bg-opacity:1;background-color:rgba(221, 162, 169, var(--tw-bg-opacity))
}.button--rounded.button--rose:hover, .button--rose.button--rounded-full:hover {--tw-bg-opacity:1;background-color:rgba(230, 189, 194, var(--tw-bg-opacity))
}.button--rounded.button--rose:active, .button--rose.button--rounded-full:active {--tw-bg-opacity:1;background-color:rgba(217, 136, 146, var(--tw-bg-opacity))
}.button--rounded.button--violet, .button--violet.button--rounded-full {--tw-bg-opacity:1;background-color:rgba(97, 76, 180, var(--tw-bg-opacity))
}.button--rounded.button--violet:hover, .button--violet.button--rounded-full:hover {--tw-bg-opacity:1;background-color:rgba(116, 98, 189, var(--tw-bg-opacity))
}.button--rounded.button--violet:active, .button--violet.button--rounded-full:active {--tw-bg-opacity:1;background-color:rgba(130, 114, 191, var(--tw-bg-opacity))
}.button--rounded.button--violet-light, .button--violet-light.button--rounded-full {--tw-bg-opacity:1;background-color:rgba(242, 240, 249, var(--tw-bg-opacity));--tw-text-opacity:1;color:rgba(97, 76, 180, var(--tw-text-opacity))
}.button--rounded.button--violet-light:hover, .button--violet-light.button--rounded-full:hover {--tw-bg-opacity:1;background-color:rgba(229, 226, 243, var(--tw-bg-opacity))
}.button--rounded.button--violet-light:active, .button--violet-light.button--rounded-full:active {--tw-bg-opacity:1;background-color:rgba(236, 233, 245, var(--tw-bg-opacity));--tw-text-opacity:1;color:rgba(145, 124, 199, var(--tw-text-opacity))
}.button--rounded.button--yellow, .button--yellow.button--rounded-full {--tw-bg-opacity:1;background-color:rgba(248, 193, 73, var(--tw-bg-opacity))
}.button--rounded.button--yellow:hover, .button--yellow.button--rounded-full:hover {--tw-bg-opacity:1;background-color:rgba(253, 217, 135, var(--tw-bg-opacity))
}.button--rounded.button--yellow:active, .button--yellow.button--rounded-full:active {--tw-bg-opacity:1;background-color:rgba(236, 179, 55, var(--tw-bg-opacity))
}.button--rounded-full {border-radius:9999px
}.button--rounded-outline, .button--rounded-outline-full {border-width:2px;font-weight:500
}.button--rounded-outline:not(.button--rounded-outline-full), .button--rounded-outline-full:not(.button--rounded-outline-full) {border-radius:1.5rem
}.button--rounded-outline.button:disabled, .button.button--rounded-outline-full:disabled, .button--rounded-outline.button:disabled:hover, .button--rounded-outline.button:disabled:focus, .button--rounded-outline.button:disabled:active {cursor:not-allowed;--tw-border-opacity:1;border-color:rgba(204, 204, 204, var(--tw-border-opacity));--tw-bg-opacity:1;background-color:rgba(255, 255, 255, var(--tw-bg-opacity));--tw-text-opacity:1;color:rgba(204, 204, 204, var(--tw-text-opacity))
}.button--rounded-outline.button--white, .button--white.button--rounded-outline-full {--tw-border-opacity:1;border-color:rgba(255, 255, 255, var(--tw-border-opacity));--tw-text-opacity:1;color:rgba(255, 255, 255, var(--tw-text-opacity))
}.button--rounded-outline.button--white:hover, .button--white.button--rounded-outline-full:hover {--tw-bg-opacity:1;background-color:rgba(255, 255, 255, var(--tw-bg-opacity));--tw-bg-opacity:0.2
}.button--rounded-outline.button--white:active, .button--white.button--rounded-outline-full:active {--tw-bg-opacity:1;background-color:rgba(255, 255, 255, var(--tw-bg-opacity));--tw-bg-opacity:0.4
}.button--rounded-outline.button--violet, .button--violet.button--rounded-outline-full {--tw-border-opacity:1;border-color:rgba(97, 76, 180, var(--tw-border-opacity));--tw-text-opacity:1;color:rgba(97, 76, 180, var(--tw-text-opacity))
}.button--rounded-outline.button--violet:hover, .button--violet.button--rounded-outline-full:hover {--tw-bg-opacity:1;background-color:rgba(242, 240, 249, var(--tw-bg-opacity))
}.button--rounded-outline.button--violet:active, .button--violet.button--rounded-outline-full:active {--tw-bg-opacity:1;background-color:rgba(236, 233, 245, var(--tw-bg-opacity))
}.button--rounded-outline.button--violet-light-to-dark, .button--violet-light-to-dark.button--rounded-outline-full {--tw-border-opacity:1;border-color:rgba(229, 226, 243, var(--tw-border-opacity))
}.button--rounded-outline.button--violet-light-to-dark:hover, .button--violet-light-to-dark.button--rounded-outline-full:hover {--tw-bg-opacity:1;background-color:rgba(229, 226, 243, var(--tw-bg-opacity))
}.button--rounded-outline.button--violet-light-to-dark:active, .button--violet-light-to-dark.button--rounded-outline-full:active {--tw-border-opacity:1;border-color:rgba(209, 201, 235, var(--tw-border-opacity));--tw-bg-opacity:1;background-color:rgba(209, 201, 235, var(--tw-bg-opacity))
}.button--rounded-outline-full {border-radius:9999px
}.button {display:flex;flex-wrap:wrap;align-items:center;overflow-wrap:break-word;line-height:1.25;transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:150ms;transition-duration:300ms
}.button:focus {outline:2px solid transparent;outline-offset:2px
}.button>.icon {display:block;height:1.25rem;width:1.25rem;color:inherit
}